// Register all components for CMS

/*
  Company Admin
 */
const ActiveVisitors = () => import("@/components/cms/companyAdmin/visitors-list/active-visitors");
const AdminDepartments = () => import("@/components/cms/companyAdmin/departments/departments");
const AdminDepartmentsEdit = () => import("@/components/cms/companyAdmin/departments/departments-edit");
const RecieverDepartments = () => import("@/components/cms/companyAdmin/recieverDepartments/departments");
const RecieverDepartmentsEdit = () => import("@/components/cms/companyAdmin/recieverDepartments/departments-edit");
const JobDepartments = () => import("@/components/cms/companyAdmin/jobDepartments/job-departments");
const JobDepartmentsEdit = () => import("@/components/cms/companyAdmin/jobDepartments/job-departments-edit");
const JobTitles = () => import("@/components/cms/companyAdmin/jobTitles/jobTitles");
const JobTitlesEdit = () => import("@/components/cms/companyAdmin/jobTitles/jobTitles-edit");
const CompanyUsers = () => import("@/components/cms/companyAdmin/Employees/users");
const CompanyUsersEdit = () => import("@/components/cms/companyAdmin/Employees/users-edit");
const DropinUsers = () => import("@/components/cms/companyAdmin/Dropin/users");
const DropinUsersEdit = () => import("@/components/cms/companyAdmin/Dropin/users-edit");
const CompanyMessages = () => import("@/components/cms/companyAdmin/messages/employersMessages/employee-messages");
const DropinMessages = () => import("@/components/cms/companyAdmin/messages/dropinMessages/messages");
const DropinMessagesEdit = () => import("@/components/cms/companyAdmin/messages/dropinMessages/messages-edit");
const VisitorMessages = () => import("@/components/cms/companyAdmin/messages/visitorMessages/messages");
const EvacuationMessages = () => import("@/components/cms/companyAdmin/messages/evacuationMessages/evacuation-messages");
const VisitexpressMessages = () => import("@/components/cms/companyAdmin/messages/visitexpressMessages/visitexpress-messages");
const PreregistrationMessages = () => import("@/components/cms/companyAdmin/messages/preregistrationMessages/preregistration-messages");
const MultiQueue = () => import("@/components/cms/companyAdmin/multiQueue/multiQueue");
const CompanySettings = () => import("@/components/cms/companyAdmin/settings/companySettings");
/*
  Common
 */
const Statistics = () => import("@/components/cms/common/statistics/statistics");
const SiteSettings = () => import("@/components/cms/common/settings/Sites/sitesSettings");
const Versions = () => import("@/components/cms/common/settings/versions");
const LoginPictureSettings = () => import("@/components/cms/common/settings/loginPictureSettings");
const LangDictionary = () => import("@/components/cms/common/settings/langDictionary");
const LangDictionaryEdit = () => import("@/components/cms/common/settings/langDictionaryEdit");

/*
  Web Constructor
 */
const Editor = () => import("@/components/cms/webConstructor/sitePageEditor/editor");
const TemplateEditor = () => import("@/components/cms/webConstructor/templatePageEditor/editor");

/*
  InfoSearch
*/
const InfoSearchBase = () => import("@/components/cms/infosearch/infoSearchBase");
const InfoSearchSites = () => import("@/components/cms/infosearch/sites/sites");
const InfoSearchAreas = () => import("@/components/cms/infosearch/areas/areas");
const InfoSearchAreasEdit = () => import("@/components/cms/infosearch/areas/areas-edit");
const InfoSearchPatients = () => import("@/components/cms/infosearch/patients/patients");
const InfoSearchPatientsEdit = () => import("@/components/cms/infosearch/patients/patients-edit");
const InfoSearchCategory = () => import("@/components/cms/infosearch/category/category");
const InfoSearchCategoryEdit = () => import("@/components/cms/infosearch/category/category-edit");
const InfoSearchStatistics = () => import("@/components/cms/infosearch/statistics");

/*
  Modules
 */
const PreRegistration = () => import("@/components/cms/modules/preregistration/preregistration");
const MeetingRoomEdit = () => import("@/components/cms/modules/meetingRoom/meetingRoom-editCustomName");
const Meetings = () => import("@/components/cms/modules/meetingRoom/meetings");
const MeetingsLog = () => import("@/components/cms/modules/meetingRoom/meetingsLog");
const MeetingEmail = () => import("@/components/cms/modules/meetingRoom/meetingEmail");
const QueueSystemLines = () => import("@/components/cms/modules/queueSystem/lines/lines");
const QueueSystemLinesEdit = () => import("@/components/cms/modules/queueSystem/lines/lines-edit");
const QueueSystemLinesManage = () => import("@/components/cms/modules/queueSystem/lines/lines-manage");
const EnturLines = () => import("@/components/cms/modules/entur/lines");
const EnturLineEdit = () => import("@/components/cms/modules/entur/line-edit");
const PreRegisterMeetings = () => import("@/components/cms/preRegisterMeeting/preRegisterMeetings");
const PreRegisterMeetingEdit = () => import("@/components/cms/preRegisterMeeting/preRegisterMeetingEdit");
const PreRegisterMeetingStatus = () => import("@/components/cms/preRegisterMeeting/preRegisterMeetingStatus");
const Redirect = () => import("@/components/cms/common/redirect");
const VisitorsCustom = () => import("@/components/cms/modules/visitorsCustom/visitors-custom");

export const cmsComponents = {
  mainCms: () => import("@/components/cms/main/main"),
  CmsContent: () => import("@/components/cms/main/CmsContent"),
  NavigationPanel: () => import("@/components/cms/layout/navigation/panels/NavigationPanel"),
  Login: () => import("@/components/cms/login/login"),
  Sites: () => import("@/components/cms/sites/sites"),
  SitePages: () => import("@/components/cms/sites/editSite/sitePages/sitePages"),
  SitesNamedContent: () => import("@/components/cms/sites/namedContent/namedContent"),
  SitesNamedContentEdit: () => import("@/components/cms/sites/namedContent/namedContentEdit"),
  Templates: () => import("@/components/cms/templates/templates"),
  EditTemplate: () => import("@/components/cms/templates/editTemplate/editTemplate"),
  TemplatePages: () => import("@/components/cms/templates/editTemplate/templatePages/templatePages"),
  ActiveVisitors: ActiveVisitors,
  Companies: () => import("@/components/cms/admin/companies/companies"),
  CompaniesEdit: () => import("@/components/cms/admin/companies/companies-edit"),
  Kiosks: () => import("@/components/cms/admin/kiosks/kiosks"),
  Logger: () => import("@/components/cms/admin/logger/logger"),
  Departments: () => import("@/components/cms/admin/departments/departments"),
  DepartmentsEdit: () => import("@/components/cms/admin/departments/departments-edit"),
  AdminDepartments: AdminDepartments,
  AdminDepartmentsEdit: AdminDepartmentsEdit,
  JobDepartments: JobDepartments,
  JobDepartmentsEdit: JobDepartmentsEdit,
  JobTitles: JobTitles,
  JobTitlesEdit: JobTitlesEdit,
  Users: () => import("@/components/cms/admin/users/users"),
  UsersEdit: () => import("@/components/cms/admin/users/users-edit"),
  CompanyUsers: CompanyUsers,
  CompanyUsersEdit: CompanyUsersEdit,
  DropinUsers: DropinUsers,
  DropinUsersEdit: DropinUsersEdit,
  CompanyMessages: CompanyMessages,
  DropinMessages: DropinMessages,
  DropinMessagesEdit: DropinMessagesEdit,
  VisitorMessages: VisitorMessages,
  EvacuationMessages: EvacuationMessages,
  VisitexpressMessages: VisitexpressMessages,
  PreregistrationMessages: PreregistrationMessages,
  UserGroup: () => import("@/components/cms/admin/user-group/user-group"),
  UserGroupEdit: () => import("@/components/cms/admin/user-group/user-group-edit"),
  Statistics: Statistics,
  PreRegistration: PreRegistration,
  Meetings: Meetings,
  MeetingsLog: MeetingsLog,
  MeetingEmail: MeetingEmail,
  LangDictionary: LangDictionary,
  LangDictionaryEdit: LangDictionaryEdit,
  SiteSettings: SiteSettings,
  Versions: Versions,
  LoginPictureSettings: LoginPictureSettings,
  MeetingRoomEdit: MeetingRoomEdit,
  Editor: Editor,
  TemplateEditor: TemplateEditor,
  InfoSearchBase: InfoSearchBase,
  InfoSearchSites: InfoSearchSites,
  InfoSearchAreas: InfoSearchAreas,
  InfoSearchAreasEdit: InfoSearchAreasEdit,
  InfoSearchPatients: InfoSearchPatients,
  InfoSearchPatientsEdit: InfoSearchPatientsEdit,
  InfoSearchCategory: InfoSearchCategory,
  InfoSearchCategoryEdit: InfoSearchCategoryEdit,
  InfoSearchStatistics: InfoSearchStatistics,
  QueueSystemLines: QueueSystemLines,
  QueueSystemLinesEdit: QueueSystemLinesEdit,
  QueueSystemLinesManage: QueueSystemLinesManage,
  EnturLines: EnturLines,
  EnturLineEdit: EnturLineEdit,
  VisitExpress: () => import("@/components/cms/visitExpress/visitExpress"),
  PreRegisterMeetings: PreRegisterMeetings,
  PreRegisterMeetingEdit: PreRegisterMeetingEdit,
  PreRegisterMeetingStatus: PreRegisterMeetingStatus,
  Redirect: Redirect,
  VisitorsCustom: VisitorsCustom,
  MultiQueue: MultiQueue,
  CompanySettings: CompanySettings,
  RecieverDepartments: RecieverDepartments,
  RecieverDepartmentsEdit: RecieverDepartmentsEdit,
};
