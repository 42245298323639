<template>
  <div class="wrapper">
    <router-view v-if="showRoutes"></router-view>
    <spinner v-if="$store.state.loading"/>
  </div>
</template>

<script>
import MiddlewareService from "@/MiddlewareService";
import addRoutesDynamic from '@/mixins/client/wc/addRoutesDynamic';
import requests from "@/mixins/admin/requests/requests";
import Vue from "vue";
import VueAWN from "vue-awesome-notifications";
import getType from "@/mixins/language/getLangType";
import clientSocket from '@/mixins/socket/socketClient';
import dictionary from "@/mixins/language/dictionary";
import less from 'less';

const spinner = () => import('@/components/cms/common/spinner');

export default {
  name: "app",
  mixins: [MiddlewareService, addRoutesDynamic, requests, getType, clientSocket, dictionary],
  data() {
    return {
      accessKey: 'AdminLayout',
      // responsible for showing routes in all app
      showRoutes: false,
    };
  },
  computed: {
    lg() {
      return this.getType();
    },
    path() {
      return this.$route?.path;
    }
  },
  components: {
    spinner
  },
  async beforeMount() {
    await this.load();
  },
  methods: {
    async load() {
      // set default lang in token to prevent fetching with undefined
      this.$store.commit('tokenLang', localStorage.lang ?? 'no');
      if (this?.lg?.length) {
        Vue.use(VueAWN, {
          duration: 2000,
          position: 'top-right',
          labels: {
            success: this.getPhrase('success'),
            warning: this.getPhrase('warning'),
            alert: this.getPhrase('alert'),
          }
        });
      }
      const parsedPath = location.hash.split(/[?/]/);
      let siteId = ''; let code = ''; let dynamic = false;
      if (parsedPath?.length) {
        dynamic = this.$route?.query?.dynamic;
        if (parsedPath[1].toLowerCase() === 'expressvisit') {
          siteId = parsedPath[2];
          dynamic = `VisitExpress&employee=${parsedPath[3]}`;
          this.$store.state.dropinObject.eEmail = parsedPath[3];
        } else if (dynamic) {
          code = parsedPath.slice(-3)[0];
          siteId = parsedPath.slice(-2)[0];
        }
      }
      if (siteId) {
        const site = await this.getClientSiteDetails(siteId, dynamic, code);
        if (!site) {
          document.body.insertAdjacentHTML('beforeend',
            `<h1 style="width: 100%; text-align: center; margin-top: 30%;">Linken er utløpt eller ødelagt. Vennligst bruk en annen lenke.</h1>`);
        } else if (site.sitePages?.length) {
          if (site.style) {
            less.render(this.$store.state.site.style).then(res => {
              document.head.insertAdjacentHTML('beforeend', `<style>${res.css}</style>`);
            });
          }
          this.initClientSocket(siteId);
          // try to push new routes to the all routes
          this.addRoutesDynamic(this.$store.state.site?.sitePages);
        }
      }
      this.showRoutes = true;
      if (!localStorage.lang) {
        localStorage.lang = 'no';
      }
    }
  }
};
</script>
<style lang="scss">
  @import '~vue-awesome-notifications/dist/styles/style.scss';
  @import '~@/styles/general/spinner';

  html {
    user-select: none;
  }
</style>
